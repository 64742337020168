<template>
    <CForm ref="form" class="needs-validation"   >
        <CInput
            label="Stok Gün Sayısı: "
            horizontal
            type="text"
            autocomplete="text"
            v-model="data.numSaleDay"
        />
   </CForm>
</template>

<script>
   export default{
       name: "InventoryForm",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
           actionType:  function(val){
               if(val == 'create')
                   this.data = {}
           }
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {},
           }
       },
   }
</script>